/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/// MODALS
// login
.login-modal .modal-wrapper {
    height: 100%;
    width: 100%;
    border: outset;
}

// Sociedad
.sociedad-modal .modal-wrapper {
    height: 45%;
    width: 60%;
    border: outset;
}

// Gasto Ingreso TIPO
.gastoingreso-modal .modal-wrapper {
    height: 20%;
    width: 30%;
    border: outset;
}

// Campaña
.campanha-modal .modal-wrapper {
    height: 50%;
    width: 40%;
    border: outset;
}

// Campaña
.gastos-modal .modal-wrapper {
    height: 80%;
    width: 30%;
    border: outset;
}

.inversion-modal .modal-wrapper {
    height: 80%;
    width: 50%;
    border: outset;
}

.gastosMulti-modal .modal-wrapper {
    height: 80%;
    width: 40%;
    border: outset;
}

// Forma Pago
.formaPago-modal .modal-wrapper {
    height: 30%;
    width: 30%;
    border: outset;
}

// Tipo explotación
.formExplotacion-modal .modal-wrapper {
    height: 30%;
    width: 20%;
    border: outset;
}

// Seleccion de cultvo
.cultivoSelect-modal .modal-wrapper {
    height: 90%;
    width: 30%;
    border: outset;
}

// Seleccion de proveedor
.proveedorSelect-modal .modal-wrapper {
    height: 50%;
    width: 30%;
    border: outset;
}

// Seleccion de campaña
.campanhaSelect-modal .modal-wrapper {
    height: 70%;
    width: 30%;
    border: outset;
}

// Seleccion de fechas
.fechas-modal .modal-wrapper {
    height: 28%;
    width: 40%;
    border: outset;
}

// Seleccion de finca
.fincaSelect-modal .modal-wrapper {
    height: 50%;
    width: 40%;
    border: outset;
}

// Banco modal
.banco-modal .modal-wrapper {
    height: 41.5%;
    width: 50%;
    border: outset;
}

.usuarioForm-modal .modal-wrapper {
    height: 40%;
    width: 30%;
    border: outset;
}

.factura-modal .modal-wrapper {
    height: 80%;
    width: 70%;
    border: outset;
}

.lineaFactura-modal .modal-wrapper {
    height: 21%;
    width: 60%;
    border: outset;
}

.lineaMultiGasto-modal .modal-wrapper {
    height: 21%;
    width: 80%;
    border: outset;
}
